/*
CHECKBOX
*/
/* .form-check-element {
  display: flex;
  align-items: center;
  background-color: white;
  border: rgba(0, 0, 0, 0.1) solid 1px;
  padding: 0.4rem 0.8rem;
  border-radius: 2rem;
  margin: 0rem 0.8rem 0 0;
  width: fit-content;
  color: var(--font-color-primary);
}

.check-label {
  width: 2rem;
  height: 2rem;
}

input[type="radio"] {
  -webkit-appearance: initial;
  appearance: initial;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  margin-left: 0.5rem;
  border: none;
  background: var(--grid-giveaway-create-background);
  position: relative;
  transition: all 0.3s;
}

input[type="radio"]:checked {
  background: var(--grid-giveaway-create-background);
  outline: var(--color-input-disabled) solid 2px;
}

input[type="radio"]:checked:after {

  content: "•";
  color: var(--color-primary-dark);
  font-family: "Poppins", sans-serif;
  font-size: 3.7rem;
  font-weight: 600;

  position: absolute;
  left: 50.5%;

  top: 44.5%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  animation: fade-in ease-in 0.3s;

} */

/******************
DROPDOWN LIST STYLES
*******************/
.Dropdown-container-popup {
  width: 100%;
  height: fit-content;
  max-height: 400px;
  overflow-y: scroll;
  margin: auto;
}

.Dropdown-item {
  width: 100%;
  display: flex;
  padding: 0.75rem 0;
  align-items: center;
  justify-content: space-between;
}
.Nav-btn {
  width: 100%;
  height: 70px;
  margin: -2px 0 6px 0;
  text-align: center;
  transition: all 0.4s;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.Nav-btn > img {
  width: 20px !important;
  height: 20px !important;
}

.Nav-btn > p {
  font-size: 0.7rem;
  font-weight: 400;
  text-transform: capitalize;
  color: rgba(255, 255, 255, 0.8);
}

.Nav-btn-active {
  background-color: #000;
  filter: invert(100%);
  /*   box-shadow: 1px 0 4px #e6e6e660; */
}

.User-avatar {
  padding-bottom: calc(22px + 0.5rem);
  text-align: center;
  border-width: 2px;
  border-style: solid;
  border-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 15%,
      #fff 15%,
      #fff 85%,
      rgba(0, 0, 0, 0) 85%
    )
    0% 0% 100% 0%;
}

.User-avatar > img {
  width: 50px;
  outline: solid 4px #fff;
  border-radius: 100%;
}

.Avatar-upload-preview {
  border-radius: 25rem;
}

.Avatar-upload-preview > img {
  object-fit: contain;
  height: 100px;
  width: 100px;
  border-radius: 25rem;
}

.Header-large {
  grid-area: header;
  padding: 0.5rem;
  background-color: #fff;
}

.Header-content {
  width: 70%;
  min-height: 70px;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Header-content > h3 {
  font-size: var(--font-size-header-large);
  font-weight: 400;
  color: var(--color-primary-dark);
}

.Header-content > h4 {
  color: var(--color-accent);
  text-align: center;
}

.Header-content > p {
  text-align: center;
  color: var(--color-primary-dark);
}

.Header-slim {
  grid-area: header;
}

.Header-medium {
  grid-area: header;
}

.Alias-header > p {
  margin-left: 2rem;
  color: var(--color-primary-dark);
  font-size: var(--font-size-header-medium);
  font-weight: 300;
}

.Header-slim,
.Header-medium {
  display: flex;
  flex-direction: column;
  background-color: var(--color-header-light);
  font-size: var(--font-size-header-medium);
  font-weight: 300;
  text-align: center;
  background-color: var(--color-header-light);
  color: var(--color-primary-dark);
  padding: 1rem 0.5rem;
}

.Header-medium > p,
.Header-slim > p {
  width: 100%;
  font-size: var(--font-size-header-medium);
  padding: 0 2rem 0 1rem;
}

.List-page-container {
  grid-area: form;
  margin: 0.3rem;
  height: calc(100% - 0.3rem);
  display: flex;
  flex-direction: column;
  background-color: var(--color-primary-light-background);
}

.List-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  flex-wrap: wrap;
  background: var(--grid-giveaway-create-background);
  padding: 1rem 0;
}

.Influencer-thumbnail {
  width: 125px;
  flex-shrink: 0;
  height: 125px;
  text-align: center;
  color: var(--color-primary-dark);
}

.Influencer-thumbnail > p {
  word-break: break-word;
}

.Footer-btn-container {
  height: 15%;
  width: 100%;
  text-align: center;
  padding-top: 0.5rem 0 0.5rem 0;
  background-color: var(--color-primary-light-background);
}

.Handle {
  width: 100%;
  max-height: fit-content;
  background-color: var(--color-primary-black-05);
  margin: 0.8rem auto;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
}

.Handle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Handle-header > img {
  width: 2rem;
}

.Handle-header > span {
  color: rgba(0, 0, 0, 0.8);
  font-size: var(--font-size-small);
}

.Handle-card-container {
  width: 100%;
  overflow-x: scroll;
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
}

.Handle-card {
  width: 6rem;
  flex-shrink: 0;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 1.5px #3b8e9e46;
  border-radius: 0.8rem;
  background-color: #fff;
}

.Handle-card > span {
  width: 100%;
  height: 5rem;
  margin: auto;
}

.Handle-card > span > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.8rem 0.8rem 0 0;
}

.Handle-card > p {
  background-color: var(--color-primary-dark);
  width: 100%;
  color: #fff;
  text-align: center;
  padding: 0.3rem;
  border-radius: 0rem 0rem 0.3rem 0.3rem;
  font-size: var(--font-size-small);
}

.Alias-tag-container {
  width: 100%;
  margin-top: 0.5rem;
}

.Alias-tag-container > p {
  font-size: var(--font-size-small);
  color: var(--color-text-dark);
  padding: 0.5rem 0.7rem;
}

.Alias-tag {
  display: inline-table;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  background-color: #fff;
  border: solid 1px var(--color-accent);
  border-radius: 10rem;
  font-size: var(--font-size-small);
  color: var(--color-primary-dark);
  cursor: pointer;
}

.Alias-tag > p {
  display: inline;
  padding: 0.5rem 0.7rem;
}

.Sidebar-list-switch-container {
  padding: 1rem;
  width: 100%;
  display: flex;
}

.Sidebar-list-switch-container > span {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  text-transform: capitalize;
  text-align: center;
  min-width: 50%;
  cursor: pointer;
  transition: color 0.2s;
}

.Sidebar-list-switch-container > span:hover {
  color: var(--font-color-primary) !important;
  font-weight: 600;
}

.Sidebar-list-switch-active {
  font-weight: 600 !important;
  color: var(--font-color-primary) !important;
  border-bottom: 2px solid var(--font-color-primary50);
}

.Popup-fit-content {
  max-width: fit-content !important;
  padding: 2rem !important;
}

.Popup-close-overlay,
.Tooltip-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 11;
}

.Popup-container,
.Popup-v2-container,
.Tooltip-container {
  position: absolute;
  top: 0;
  left: 0;
  height: var(--full-viewport-height);
  width: 100vw;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Popup-box,
.Tooltip-content {
  background-color: #fff;
  width: var(--popup-box-width);
  /*   max-width: fit-content; */
  min-height: fit-content;
  max-height: 600px;
  border-radius: 1rem;
  padding: 1rem 1rem;
  animation: slide-up 0.3s ease-in;
  z-index: 12;
}

.Popup-v2-box {
  background-color: #fff;
  width: var(--popup-box-width);
  min-height: 220px;
  max-height: 600px;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 12;
  padding: 30px 15px;
}

.Popup-v2-box > button {
  align-self: center;
}

.Tooltip-content {
  height: 600px;
}

.Popup-header-empty {
  width: var(--popup-box-width);
  position: relative;
  text-align: right;
  padding: 1rem 0;
}

.Popup-v2-header-empty {
  width: var(--popup-box-width);
  position: relative;
  text-align: right;
  margin-bottom: 1rem;
}

.Guide-header {
  background-color: #e7f5e8;
  margin: -1rem -1rem 0 -1rem;
  padding: 1rem 1rem 0 1rem;
  border-radius: 1rem 1rem 0 0;
  border-bottom: solid 2px #089818;
}

.Guide-header > h4 {
  color: #089818;
  font-size: 18px;
  font-weight: 600;
}

.Tab-switch > button {
  color: #6e746f;
  border-radius: 0;
}

.Tab-switch > button:hover {
  background: none;
}

.Tab-switch-active {
  color: #089818 !important;
  border-bottom: solid 1px #089818;
  font-weight: 600;
}

.Tooltip-step-text {
  font-weight: 600;
  color: #6e746f;
}

.Close-popup-round {
  background-color: #fff;
  width: 35px;
  height: 35px;
  padding: 5px;
  border-radius: 100%;
}

.Close-popup-round > img {
  width: 25px;
  height: 25px;
}

.Popover {
  position: relative;
  left: 10px;
  bottom: calc(70px + 1rem);
  z-index: 10;
}

.Popover > ul {
  position: absolute;
  border: 2px solid rgba(0, 0, 0, 0.06);
  background-color: #fff;
  padding: 1rem;
  box-shadow: 4px 1px 12px 6px rgba(0, 0, 0, 0.06);
  border-radius: 0.8rem;
}

.relative-box {
  position: relative;
  width: calc(100% + 2rem);
  z-index: 10;
  margin-top: -1rem;
  margin-left: -1rem;
}
.overlay-black-giveaway-list-card {
  position: absolute;
  width: 100%;
  height: 35rem;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 1rem;
}

.Connect-status-text {
  min-width: 100vw;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
}

.Connect-status-container {
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: -10rem;
  min-width: 100vw;
  min-height: 100vh;
}

.Connect-success {
  background-image: url("../img/registration/connect_success_backdrop.png");
}

.Connect-failed {
  background-image: url("../img/registration/connect_failed_backdrop.png");
}

@keyframes slide-up {
  from {
    transform: translate(0%, 100%);
  }
  to {
    transform: translate(0%, 0%);
  }
}

.Popup-header {
  border-bottom: dashed 2px var(--color-accent-20);
  width: 100%;
  padding: 1rem;
}

.Popup-header > p {
  display: inline;
  font-weight: 600;
  color: var(--color-primary-black);
}

.Popup-header > button {
  display: inline;
  float: right;
}

.Popup-header > button:hover {
  background: none;
}

.Popup-content {
  padding: 1rem;
  font-size: 0.9rem;
}

.Popup-content > button {
  margin-top: 1rem;
}

.Connect-code-input {
  width: 100%;
  border: none;
  background-color: var(--color-input-background);
  padding: 1rem 0.8rem;
  margin: 1rem 0;
  border-radius: 0.6rem;
  color: var(--color-primary-dark);
  font-size: 1rem;
}

.Connect-code-input:focus {
  border: solid 2px var(--color-input-outline-active);
  background-color: var(--color-input-background-filled);
}

.Upload-progress {
  position: absolute;
  top: 0;
  height: 100%;
  background-color: var(--color-accent);
  border-radius: 0.5rem;
  transition: all 0.5s;
}

.Upload-progress-text {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  top: 0.5rem;
  bottom: 0.5rem;
}

.Popup-uploaded-preview-mini {
  margin: auto;
  width: 50%;
  height: 10rem;
}

.Popup-uploaded-preview-square {
  margin: auto;
  width: 125px;
  height: 125px;
}

.Popup-uploaded-preview {
  width: 100%;
  height: 10rem;
}

.Popup-uploaded-preview > img,
.Popup-uploaded-preview-square > img {
  object-fit: cover;
  border-radius: 1rem;
}

.Quill-container {
  height: 100px;
}

.Quill-container > .ql-toolbar.ql-snow {
  border: none !important;
  border-bottom: 1px solid #e6e6e6 !important;
  border-radius: 0.5rem 0.5rem 0 0;
}

.Quill-container > .ql-container.ql-snow {
  border: none !important;
  border-radius: 0 0 0.5rem 0.5rem;
}

.Quill-container > div {
  background-color: var(--color-input-background);
}

.Choose-file-option {
  text-align: center;
}

.Choose-file-option > input {
  display: none;
}

.Choose-file-button {
  color: var(--color-primary-dark);
  text-decoration: underline;
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
}

.Upload-option > input {
  width: 100%;
  padding: 0.8rem 0.5rem;
  border-radius: 0.7rem;
  border: solid rgba(0, 0, 0, 0.2) 0.13rem;
}

.Confirmation-code-container {
  color: var(--color-accent);
  background-color: #f4f4f4;
  display: flex;
  font-size: 1.3rem;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-weight: 500;
  margin: 1.5rem 0;
  overflow-x: hidden;
}

.Confirmation-code-container > div {
  width: 100%;
  word-wrap: nowrap;
  overflow-x: scroll;
}

.Confirmation-code-container::-webkit-scrollbar,
.Confirmation-code-container > p::-webkit-scrollbar,
.Confirmation-code-container > div::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.Popup-note {
  font-size: 0.8rem;
}

.share-button {
  background-color: #fff;
  padding: 0.25rem 0.25rem;
}

.share-button:hover {
  background-color: #fff;
}

.share-button > img {
  height: 2rem;
}

@keyframes ProgressCounter {
  0% {
    transform: translateY(100%);
    opacity: 0%;
  }
  50% {
    transform: translateY(0%);
    opacity: 100%;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0%;
  }
}

/* .Bottom-sheet-container {
  width: 100vw;
  height: var(--full-viewport-height);
  position: fixed;
  z-index: 13;
  display: grid;
  top: 0;
  left: 0;
}

.Bottom-sheet-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.Bottom-sheet {
  width: 375px;
  max-height: 600px;

  position: fixed;
  z-index: 11;
  align-self: center;
  justify-self: center;
  background: #fff;

  overflow-y: scroll;
  padding-bottom: 32px;
  border-radius: 14px;
}

.Bottom-sheet::-webkit-scrollbar,
.Bottom-sheet::-webkit-scrollbar-thumb {
  width: 0 !important;
  height: 0 !important;
}

.Bottom-sheet-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 16px;

  font-size: var(--font-size-H2);
  font-weight: var(--font-weight-semibold);
  color: var(--color-primary-black);
}

.Bottom-sheet > * {
  padding-left: 24px;
  padding-right: 24px;
} */

.Announcement-banner-mobile {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 -0.5rem;
  padding: 12px 16px;
  gap: 10px;
  border-left-width: 4px;
  border-left-style: solid;
}

.Claim-banner-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 -0.5rem;
  padding: 12px 16px;
  background-color: var(--color-accent-20-solid);
  gap: 8px;
}

.Announcement-banner-desktop {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  padding: 24px 24px;
  border-left-width: 12px;
  border-left-style: solid;
  width: calc(100% - 40px);
  margin: 20px auto;
  border-radius: 8px;
}

.Claim-banner-desktop {
  grid-area: page-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  background-color: var(--color-accent-20-solid);
  padding: 24px 24px;
  margin: 0 auto;
}

@media only screen and (max-width: 600px) {
  .Popup-v2-container {
    justify-content: flex-end;
  }

  .Popup-v2-box {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 800px) {
  .Popup-v2-box {
    max-width: 400px;
    margin-bottom: 40px;
  }

  .Popup-v2-header,
  .Popup-v2-header-empty {
    max-width: 400px;
  }
  .Header-content {
    width: 80%;
    min-height: 70px;
  }

  .Header-large {
    padding: 1rem 0;
  }

  .Dropdown-container-popup {
    max-height: calc(var(--vh) * 70);
  }

  .Connect-status-container {
    background-color: unset;
  }
  /* 
  .Bottom-sheet {
    width: calc(100vw - 32px);
    max-height: 85vh;
    left: 16px;
    bottom: 16px;
  } */
}
