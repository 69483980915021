/****************************
##############################
******** TEXT ****************
##############################
*****************************/

.fw-100 {
  font-weight: 100;
}

.fw-200 {
  font-weight: 200;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.fc-white {
  color: #fff;
}

.fc-pb {
  color: var(--color-pb);
}

.fc-pb-03 {
  color: var(--color-pb-03-solid);
}

.fc-pb-05 {
  color: var(--color-pb-05-solid);
}

.fc-pb-10 {
  color: var(--color-pb-10-solid);
}

.fc-pb-20 {
  color: var(--color-pb-20-solid);
}

.fc-pb-40 {
  color: var(--color-pb-40-solid);
}

.fc-pb-60 {
  color: var(--color-pb-60-solid);
}

.fc-pb-80 {
  color: var(--color-pb-80-solid);
}

.fc-accent {
  color: var(--color-accent-solid);
}

.fc-pi-gradient {
  background: var(--color-pi-gradient);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.fc-accent-gradient {
  background: var(--color-accent-gradient);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bkg-accent-10-solid {
  background-color: var(--color-accent-10-solid);
}

.text-underline {
  text-decoration: underline;
}

.mt-8 {
  margin-top: 8px;
}

.mb-16 {
  margin-bottom: 16px;
}

/**unset**/
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div,
p,
h1,
h2,
h3,
button,
label,
input,
form,
h4 {
  box-sizing: border-box;
  outline: unset;
  border: unset;
  font-weight: unset;
  margin: unset;
  padding: unset;
  font-family: "Poppins", sans-serif;
}
