.Dashboard-layout {
  display: grid;
  height: 100%;
  grid-template-areas: var(--grid-dashboard-layout-areas);
  grid-template-rows: var(--grid-dashboard-layout-rows);
  grid-template-columns: var(--grid-dashboard-layout-columns);
}

.Dashboard-container {
  grid-area: body_content;
  display: grid;
  grid-template-areas: var(--grid-dashboard-areas);
  grid-template-rows: var(--grid-dashboard-rows);
  grid-template-columns: var(--grid-dashboard-columns);
}

.Sidebar-header-light {
  grid-area: sidebar_header;
  background-color: #fff;
  border-image: linear-gradient(
      to right,
      #fff 15%,
      var(--color-accent) 15%,
      var(--color-accent) 85%,
      #fff 85%
    )
    0% 0% 100% 0%;
  border-width: 2px;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.Sidebar-header-light > h3 {
  font-size: var(--font-size-heading);
  font-weight: 600;
  text-align: center;
  color: var(--font-color-black);
}

.Sidebar-menu-light {
  grid-area: sidebar_menu;
  background-color: #fff;
}

.Sidebar-header {
  grid-area: sidebar_header;
  background-color: #e6e6e6;
}

.Sidebar-menu {
  grid-area: sidebar_menu;
  background-color: #e6e6e6;
  color: var(--font-color-black);
}

.manager-sidenav {
  padding-top: 100px;
  grid-area: sidenav;
  display: var(--visibility-desktop) !important;
  background: linear-gradient(
    180deg,
    #003370 0%,
    #00407a 60%,
    #013f79 80%,
    #03216d 100%
  );
}

.influencer-sidenav {
  padding-top: 100px;
  grid-area: sidenav;
  display: var(--visibility-desktop) !important;
  /*  background: linear-gradient(
    180deg,
    #c33367 0%,
    #c26161 60%,
    #a13c5b 80%,
    #c24077 100%
  ); */
  background: linear-gradient(to bottom, #7252f0, #8729d1);
}

.Manager-profile-card,
.Manager-profile-card-mobile {
  padding: 1rem 1.3rem;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  justify-content: center;
  border-bottom: #f0f0f0 solid 2px;
}

.Manager-profile-card {
  grid-area: sidebar_header;
  height: 135px;
  border-right: solid 2px #f0f0f0;
}

.Manager-profile-card-mobile {
  grid-area: page_content;
  width: 100%;
  height: 75px !important;
  padding-top: 0rem;
}

.Side-menu-mobile,
.Side-menu-large-mobile {
  grid-area: page_content;
  width: 100%;
}

.Side-menu {
  grid-area: sidebar_menu;
  border-right: solid 2px #f0f0f0;
}

.Side-menu-large {
  width: 20.5rem !important;
}
.Empty-side-menu-button-card {
  min-width: 18rem;
  max-width: 18rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80%;
}

.Empty-side-menu-button-card > p {
  width: 70%;
  font-size: 1.2rem;
  text-align: center;
  font-weight: 600;
  color: var(--font-color-primary50);
}

.Empty-side-menu-button-card > button {
  width: fit-content;
  padding: 0.5rem 2rem;
}

.Inf-profile-container {
  display: var(--display-container);
  overflow-x: none;
  max-height: 80%;
  overflow-y: scroll;
  padding: 1rem 0.5rem;
}

.Dashboard-sidebar-heading {
  font-size: 18px;
  font-weight: 700;
  background: var(--gradient-bg);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Inf-profile-card {
  min-width: 18rem;
  max-width: 18rem;
  height: 135px;
  font-size: 0.9rem;
  background-color: #fff;
  border-radius: 1rem;
  margin: 1rem 0.5rem;
  padding: 0.5rem;
  display: grid;
  grid-template-rows: 1fr 2fr 2fr;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 0px 10px;
  grid-template-areas:
    "avatar name name msg"
    "avatar stat stat stat"
    "platform platform platform platform";
  transition: background-color 0.3s;
  border: #ededed solid 1px;
}

.Inf-avatar {
  height: 65px;
  width: 65px;
  grid-area: avatar;
  /*box-shadow: 0px 4px 8px #70707091;*/
  border-radius: 100%;
  margin-right: 0.5rem;
}

.Inf-name {
  grid-area: name;
  padding-top: 0.3rem;
}

.Inf-stat {
  grid-area: stat;
}

.Inf-state > p {
  width: max-content;
  margin-top: 0.3rem;
  padding: 0.2rem 0.5rem;
  border-radius: 0.4rem;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 0.85rem;
}

.Inf-state-disconnected > p {
  background-color: #ffe7e7;
  color: #ff6363;
}

.Inf-state-connected > p {
  background-color: #d2f4de;
  color: #099472;
}

.Inf-chat {
  grid-area: msg;
  width: 40px;
  height: 40px;
}

.Platform-overview-button {
  grid-area: platform;
  height: calc(100% + 0.6rem);
  width: calc(100% + 1.1rem);
  margin-left: -0.5rem;
  border-radius: 0 0 1rem 1rem;
  text-align: center;
}

.Platform-overview-button > button {
  height: 100%;
  width: 100%;
}

.btn-state-connected {
  background-color: #d2f4de;
  color: #099472;
}

.btn-state-connected > button {
  color: #099472;
}

.btn-state-connected > button:hover {
  background: none !important;
}

.btn-state-disconnected {
  background-color: #000;
  color: #fff;
}

.btn-state-disconnected > button {
  color: #fff;
}

.btn-state-disconnected > button:hover {
  background: none !important;
}

.Platform-overview-container {
  grid-area: platform;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
}

.Platform-icon {
  height: 25px;
}

.Platform-count-indicator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.05);
  width: 5rem;
  padding: 0.2rem 0.3rem;
  margin: 0 0.3rem;
  border-radius: 3rem;
  height: 2rem;
}

.Platform-count-text {
  background-color: #fff;
  border-radius: 100%;
  width: 1rem;
  height: 1rem;
  text-align: center;
  font-size: 0.7rem;
}

/*********************
EMPTY DASHBOARD CONTENT
**********************/
.Background-popup {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
.New-user-container {
  width: var(--giveaway-create-container-width);
  height: 85%;
  overflow-y: scroll;
  display: grid;
  grid-area: page_content;
  grid-template-areas: var(--grid-empty-dashboard-areas);
  grid-template-columns: var(--grid-empty-dashboard-columns);
  grid-template-rows: var(--grid-empty-dashboard-rows);
  padding: 0 1rem;
  color: rgba(0, 0, 0, 0.8);
  background-color: #fff;
  position: absolute;
  margin: auto;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  border-radius: 2rem;
  z-index: 1;
}

.Role-selection-heading {
  grid-area: heading;
  margin-top: 1rem;
}

.Role-selection-container {
  grid-area: role_selection;
  text-align: center;
}

.Role-selection-container > div {
  display: inline-block;
}

.Influencer-tab,
.Manager-tab {
  height: 8rem;
  width: 8rem;
  margin: 0 2rem;
}

.Influencer-tab > p,
.Manager-tab > p {
  font-weight: 700;
  text-align: center;
}

.Influencer-tab-selected {
  height: 14rem;
  width: 14rem;
  background-position: center;
  background-size: 105%;
  /*   border-radius: 5rem; */
  text-align: center;
}

.Manager-tab-selected {
  height: 14rem;
  width: 14rem;
  background-position: center;
  background-size: 120%;
  /*   border-radius: 5rem; */
  text-align: center;
}

.Influencer-tab-selected {
  background-image: url(../img/registration/influencer_tab.png);
}

.Manager-tab-selected {
  background-image: url(../img/registration/manager_tab.png);
}

.Manager-tab > img,
.Influencer-tab > img {
  filter: grayscale(100%);
}

.Influencer-tab-selected > img {
  /*   border-radius: 5rem; */
  width: 80%;
  height: 80%;
  margin-top: 10%;
}

.Manager-tab-selected > img {
  /*   border-radius: 5rem; */
  width: 80%;
  height: 80%;
  margin-top: 10%;
  margin-left: 12%;
}

/* .Influencer-tab-selected > img {
  background: linear-gradient(45deg, #f37be0, #886eef);
}

.Manager-tab-selected > img {
  background: linear-gradient(45deg, #82a5ff, #3554a1);
} */

.Role-content-container {
  grid-area: role_content;
  text-align: center;
  padding: 2rem;
}

.Role-content-container > p {
  color: var(--color-accent);
}

.Role-details {
  text-align: center;
}

.Role-details > p {
  margin: auto;
  width: 70%;
}

.Action-button-container {
  grid-area: action_button;
  text-align: center;
}

.Action-button-container > button {
  margin: 0rem auto;
}

.Superpower-accent {
  color: #95b7ff;
}

.Superpowers {
  grid-area: superpowers;
  background-color: #fff;
  padding: 2rem 0.5rem;
  text-align: center;
}

.Superpowers-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Superpower {
  padding: 0.5rem 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 7rem;
  height: 7.5rem;
  margin: 1rem 0.3rem;
  border: 0.2rem solid #e9f0ff;
  border-radius: 2rem 2rem 1rem 1rem;
}

.Superpower-img {
  height: 4rem;
}

.Superpower-text {
  min-height: 2rem;
  padding: 0 0.5rem;
  font-size: 0.7rem;
  font-weight: 500;
  color: #95b7ff;
  text-align: center;
  background-color: #e8effd;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0rem 0rem 0.7rem 0.7rem;
}

.span-text-small {
  font-size: 1.25rem;
}

.Many-more-text {
  color: #95b7ff;
  margin: auto;
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 1rem;
}

.Empty-dashboard-content {
  grid-area: page_content;
  /*   background-color: #f8f8f8; */
  height: 100%;
  width: 100%;
  /*   overflow-y: scroll; */
  padding: 1rem;
  display: grid;
}

.Empty-dash-ph-container {
  margin: auto;
  width: 80%;
  aspect-ratio: 2/1;
  background-image: url("../img/svg/empty_placeholder_dashboard.svg");
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 0.8rem;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.Empty-dash-ph-graphic {
  height: 108px;
}

.Empty-dash-ph-header {
  font-size: 32px;
  padding: 12px 0;
  border-width: 2px;
  border-style: solid;
  border-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 25%,
      #fff 25%,
      #fff 75%,
      rgba(0, 0, 0, 0) 75%
    )
    0% 0% 100% 0%;
  margin-bottom: 12px;
}

.Empty-dash-ph-subtitle {
  font-size: 20px;
}

.Placeholder-container {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  height: fit-content;
  width: 100%;
  flex-wrap: wrap;
  overflow-y: hidden;
}

.Placeholder-container > h2 {
  display: flex;
  align-self: flex-end;
  width: 100%;
  color: rgba(0, 0, 0, 0.6);
  margin: 2rem 1rem;
}

.Placeholder-card {
  display: grid;
  background-color: #f0f0f0;
  flex-shrink: 0;
  width: 30%;

  height: 25vh;
  margin: 2rem 1rem;
  border-radius: 1rem;
}

.Placeholder-card > div {
  margin: auto;
  text-align: center;
}
@media only screen and (min-width: 1000px) and (max-width: 1400px) {
  .Placeholder-card {
    max-width: 220px;
  }
}

@media only screen and (max-width: 800px) {
  .Dashboard-container {
    overflow-y: scroll;
  }

  .New-user-container {
    position: absolute;
    left: 0;
    height: 100%;
    border-radius: 0;
  }

  .Role-content-container {
    width: 100%;
  }

  .Role-details > p {
    width: 100%;
  }
  .Superpowers {
    background-color: #f6f7fa;
    margin: -0.5rem;
    padding: 1rem;
  }

  .Placeholder-card {
    width: 85%;
    max-width: 85%;
  }

  .Placeholder-container {
    justify-content: center;
    height: fit-content;
  }

  .Role-selection-container {
    grid-area: role_selection;
    text-align: center;
  }

  .Role-selection-container > div {
    display: inline-block;
  }

  .Influencer-tab,
  .Manager-tab {
    height: 6rem;
    width: 6rem;
    margin: 3.5rem 7%;
  }

  .Influencer-tab > p,
  .Manager-tab > p {
    font-weight: 700;
  }

  .Influencer-tab-selected {
    height: 12rem;
    width: 12rem;
    background-position: center;
    background-size: 100%;
  }

  .Manager-tab-selected {
    height: 12rem;
    width: 12rem;
    background-position: center;
    background-size: 115%;
  }
  .Influencer-tab-selected > img {
    width: 80%;
    height: 80%;
    margin-top: 10%;
  }

  .Manager-tab-selected > img {
    width: 80%;
    height: 80%;
    margin-top: 10%;
    margin-left: 10%;
  }

  /*   .Influencer-tab-selected > img {
    background: linear-gradient(45deg, #f37be0, #886eef);
  }

  .Manager-tab-selected > img {
    background: linear-gradient(45deg, #82a5ff, #3554a1);
  } */

  .Placeholder-card {
    width: 28rem;
  }
  .Empty-side-menu-button-card {
    width: 100%;
    height: 12rem;
    margin: auto;
  }
  .Inf-profile-container {
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .Empty-dashboard-content {
    background-color: #fff;
    display: block;
    padding: 0.5rem;
  }

  .Empty-dashboard-content-mobile {
    display: block;
    background-color: #fff;
    /*   height: 100%; */
    width: 100%;
    overflow-y: visible;
    padding: 1rem 0;
    border-top: solid 2px #fafafa;
  }

  .Empty-dash-ph-container {
    background-image: url("../img/svg/empty_placeholder_dashboard_mobile.svg");
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 450px;
    padding: 0 12px;
  }

  .Empty-dash-ph-graphic {
    height: 80px;
    margin: 10% 0;
  }
  .Empty-dash-ph-header {
    font-size: 24px;
    border-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0) 5%,
        #fff 5%,
        #fff 95%,
        rgba(0, 0, 0, 0) 95%
      )
      0% 0% 100% 0%;
  }
  .Empty-dash-ph-subtitle {
    font-size: 14px;
    width: 100%;
    padding: 0 28px;
  }
}
