h1,
h2,
h3,
h4,
p {
  font-family: "Poppins", sans-serif;
  margin: unset;
}

div {
  box-sizing: border-box;
}

.Manager-layout {
  display: grid;
  grid-template-columns: var(--grid-dashboard-columns);
  grid-template-rows: var(--grid-dashboard-rows);
  gap: 2px;
  grid-auto-flow: 0;
  grid-template-areas: var(--grid-dashboard-areas);
  height: 100vh;
}

.Manager-sidebar {
  grid-area: influencer_list;
  background-color: #fff;
  transition: all 0.3s;
}

.Manager-sidebar-small {
  grid-area: influencer_list;
  background-color: #fff;
  width: 250px;
}

.Inf-header {
  grid-area: influencer_list;
  padding: 1rem 0.5rem 1rem 1.3rem;
}

.Inf-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
}

.search-input {
  margin: 0 !important;
  height: 2.5rem !important;
  width: 100% !important;
  background-color: white !important;
  border-radius: 0.5rem !important;
  outline: none;
  border: none;
}

.shadow-container {
  position: relative;
}

.blur-shadow {
  position: absolute;
  left: 0.5rem;
  top: 1.3rem;
  border-radius: 100%;
  background-color: #707070;
  filter: blur(10px);
  z-index: -10;
  height: 45px;
  width: 45px;
  overflow: hidden;
}

.blur-shadow > img {
  height: 80px;
  width: 80px;
}
.Heading {
  font-size: 1.5rem;
}

.Giveaway-nav {
  padding: 3rem 1.5rem;
}

.Giveaway-nav-btn-container {
  padding: 1rem 1rem;
}

.Sidenav-btn {
  height: 3.5rem;
  font-size: 1rem;
  padding: 1rem 1.5rem 1rem 1rem;
  margin: 0.5rem 0;
}

.Grid-content {
  grid-area: bodycontent;
  background-color: #f8f8f8;
  padding: 3rem 5rem;
}
