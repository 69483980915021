.Registration-layout-grid {
  background: #3b8d9e;
  display: grid;
  grid-template-rows: var(--full-viewport-height);
  grid-template-areas: var(--grid-registration-layout-areas);
  grid-template-columns: var(--grid-registration-layout-columns);
}

.Grid-register-infographic {
  grid-area: infographic;
  height: 100%;
  width: 100%;
  background-image: url("../img/sign_in_res/sign_in_bkg.png");
  background-size: 70%;
  background-position: center 20vh;
  background-repeat: no-repeat;
}

.Grid-form-container {
  grid-area: form;
  padding: 0.8rem;
}

.Account-page {
  grid-area: page_content;
  background-color: #eceef8;
  height: 100%;
  width: 100%;
}

.Profile-form-page {
  grid-area: page_content;
  /*   background-color: #eceef8; */
  height: 100%;
  width: 100%;
}

.Profile-form-dashboard-container {
  width: var(--giveaway-create-container-width);
  background: var(--grid-giveaway-create-container-background);
  display: grid;
  grid-template-areas: var(--grid-giveaway-create-areas);
  grid-template-columns: var(--grid-giveaway-create-columns);
  grid-template-rows: var(--grid-giveaway-create-rows);
  margin: 3vh auto;
  padding: 1rem;
  border-radius: 1.5rem;
  height: 85vh;
  overflow-y: scroll;
}

/****************************************
*
*
STYLES FOR ONBOARDING
*
*
****************************************/
.Ob-page {
  width: 100%;
  height: var(--full-viewport-height);
  background: linear-gradient(to bottom, #6301d3, #c2c7f4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Ob-login-container {
  width: clamp(90vw, 90vw, 300px);
  max-width: 360px !important;
  padding: 15px;
  background-color: #fff;
  border-radius: 15px;
  padding: 16px 24px;
}

.Ob-login-heading {
  margin-bottom: 40px;
}

.Ob-footer {
  margin-top: 28px;
  font-size: 12px;
  text-align: center;
}

.Ob-logo {
  height: 80px;
}

.Ob-logo > img {
  height: 40px;
  margin: auto;
}

.Ob-carousel-container {
  max-height: 65vh;
  display: flex;
  gap: 20px;
  padding: 0px 20px;
  justify-content: center;
  overflow-y: scroll;
}

.Ob-card-container {
  /*width: 30%;*/
  max-width: 330px;
  /* height: 500px; */
  background: linear-gradient(to bottom, #c3e4eb, rgba(255, 255, 255, 0));
  text-align: center;
  border-radius: 20px 20px 0 0;
  padding: 1px;
}

.Ob-card {
  min-width: 100%;
  height: 100%;
  border-radius: inherit;
  background: linear-gradient(to bottom, #77b0bc, rgba(255, 255, 255, 0));
}

.Ob-card-caption {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  padding: 30px 0 20px 0;
  line-height: 30px;
}

.Ob-card-image {
  width: 100%;
  margin: auto;
  -webkit-user-drag: none;
  pointer-events: none;
}

.Ob-page-influencer {
  background: linear-gradient(to bottom, #4291a1, #c2d8d8);
  height: var(--full-viewport-height);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Ob-buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  align-items: center;
}

.Signup-input {
  display: flex;
  align-items: center;
  width: 100% !important;
  height: 3rem;
  font-size: 14px;
  outline: none;
  border: solid 1px #e5e4e7;
  gap: 12px;
  border-radius: 0.8rem;
  padding: 12px 16px;
}

.Country-code-drop {
  width: fit-content !important;
  gap: 14px;
}

.Ob-phone-signin-input {
  max-width: 65%;
  border: none !important;
  color: #1f1f1f !important;
  font-weight: 500 !important;
  font-size: 18px !important;
}

.Ob-phone-signin-input::placeholder {
  color: #a3a2a4 !important;
  font-weight: 500;
}

.Ob-phone-signin-input:focus {
  outline: none !important;
}
/****************************************
STYLES FOR LOGIN / SIGN UP SCREEN
****************************************/
.logo-header {
  width: 10rem;
  padding: 1rem;
}

.Login-options-container {
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Signin-heading {
  font-weight: 400;
  font-size: 0.8rem;
  color: var(--color-primary-dark);
  margin-bottom: 0.5rem;
}

.Signin-heading > p {
  width: 20rem;
}
.Signin-form-container {
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: 1rem;
}

/* .Signup-input {
  display: flex;
  align-items: center;
  justify-content: center;
} */

.Signup-input > button {
  margin-left: -2.5rem;
}

/* .Signup-input > input,
.Signup-input > .div-input {
  width: var(--width-input-large);
  height: 3rem;
  background: var(--color-primary-light-background);
  border-radius: 0.5rem;
  outline: none;
  border: none;
  padding: 0.3rem;
  color: var(--color-primary-dark);
  font-weight: 500;
  font-size: 1.2rem;
}
 */
/* .Signup-input > input,
.Signup-input > .div-input {
  width: var(--width-input-large);
  height: 3rem;
  font-size: 14px;
  outline: none;
  border-style: solid;
  border-color: #e5e4e7;
  border-width: 1px;
  gap: 4px;
} */

.Country-code-drop > img {
  width: 30px;
}

.Signup-input > input[type="dropdown"],
.Signup-input > .div-input {
  border-right-width: 0px;
}

.Signup-input > input[type="number"] {
  border-left-width: 0px;
}

.Signup-input > input[type="number"]::placeholder {
  color: #e5e4e7;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.Phone-signin-container {
  margin-bottom: 1rem;
}

.Social-signin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Social-signin-container > button {
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  margin: 1rem 1rem 0.5rem 1rem;
  padding: 0 0.5rem 0 0;
  font-weight: 700;
  font-size: 0.9rem;
  font-family: "Roboto", sans-serif;
  border-radius: 0.8rem !important;
}

.google-signin-btn {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.7);
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.fb-signin-btn {
  background-color: var(--color-facebook-blue);
  color: #fff;
}

.fb-signin-btn > img,
.google-signin-btn > img {
  width: 2.5rem;
  margin-right: 2rem;
}

/* .google-signin-btn > span, .fb-signin-btn>span {
  width: 100%;
  text-align: center;
} */

.google-signin-btn:hover {
  background-color: #fff;
}

.fb-signin-btn:hover {
  background-color: var(--color-facebook-blue);
}

.Divider-header {
  width: 100%;
  margin-top: 1rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.3);
}

.Divider-line {
  width: 10rem;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  margin: 0.5rem;
}

/****************************************
STYLES FOR PERSONAL PROFILE REGISTERATION
****************************************/
.Profile-form-container {
  grid-area: form;
  height: 100%;
  background-color: #fff;
  /*   display: flex;
  flex-direction: column; */
  border-radius: 0.8rem;
  overflow-x: hidden;
}

.Profile-form {
  flex-grow: 1;
  height: 100%;
  overflow-y: scroll;
  padding: 1.5rem 1.7rem 1.5rem 1.7rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  background: var(--grid-giveaway-create-background);
  border-bottom-left-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
  border-top: solid 1px var(--color-input-outline-outline);
}

.Profile-form > form {
  width: 70%;
  /*  max-height: 100%; */
  padding-bottom: 1rem;
}

form > p {
  font-size: var(--font-size-small);
  color: var(--color-primary-dark);
}

.form-input-container {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.error-text {
  color: var(--color-danger-text);
  font-size: var(--font-size-small);
  font-weight: 600;
  /*   background-color: var(--color-danger-box-title); */
  padding: 0.3rem;
  border-radius: 0rem 0rem 0.3rem 0.3rem;
}

.form-input-container > input[type="text"]:disabled {
  background-color: rgba(0, 0, 0, 0.07);
  color: var(--color-input-disabled-text);
}

.form-input-container > input[type="text"],
.form-input-container > input[type="datetime-local"],
.form-input-container > textarea,
.form-input-container > select {
  width: 100%;
  border: none;
  outline: none;
  background-color: var(--color-input-background);
  height: 3.3rem;
  border-radius: 0.4rem;
  color: var(--color-primary-dark);
  font-weight: 400;
  font-size: var(--font-size-input-text);
  padding-left: 1.5rem;
  transition: outline-color 0.5s;
}

.form-input-container > input:focus {
  border: 2px solid var(--color-primary-dark);
}

::placeholder {
  color: var(--font-color-primary50);
  font-weight: 400;
  font-size: var(--font-size-input-text);
}

.form-input-radio-group {
  display: flex;
  align-items: center;
}

.form-input-radio-group > label {
  font-size: 0.9rem;
  color: var(--color-primary-dark);
  margin-right: 1rem;
}

.form-input-radio-group-scrollable {
  width: 100%;
  overflow-x: scroll;
}

.Radio-slab-list,
.Radio-slab-list-scrollable {
  list-style: none;
  margin: 0.3rem 0 0.6rem 0 !important;
  padding: unset;
  width: 100%;
}

.Radio-slab-list-scrollable {
  display: flex;
  margin-top: 0.3rem;
}

.Radio-slab-list-scrollable > li {
  width: max-content;
  font-size: 0.8rem;
  font-weight: 600;
}

.Radio-slab-list > li {
  display: inline-block;
  font-size: var(--font-size-SH3);
  font-weight: 500;
}

.Radio-slab-option {
  margin: 0.7rem 0.3rem;
}
.Radio-slab-option > input[type="radio"],
.Radio-slab-option > input[type="checkbox"] {
  visibility: hidden;
  width: 0;
  margin: unset;
}
.Radio-slab-option > label {
  color: var(--color-primary-dark);
  background-color: var(--color-input-background);
  padding: 0.5rem 0.8rem;
  cursor: pointer;
  border-radius: 15rem;
}

.Radio-slab-option > input:checked + label {
  color: white;
  background-color: var(--color-accent);
}

.Form-action-items {
  /* grid-area: action_items; */
  text-align: center;
  padding-top: 0.5rem 0 0.5rem 0;
  /* background: var(--grid-giveaway-create-background); */
  /*   border-radius: 0 0 2rem 2rem; */
}

.Form-action-items-flex {
  min-height: 10%;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.Form-action-items > button {
  display: block;
  margin: 1rem auto 0 auto;
}

.Connection-options {
  display: flex;
}

/****************************************
STYLES FOR ALIAS CREATION
****************************************/
.Alias-input {
  display: flex;
  height: 2.5rem;
  width: 100%;
}
.Alias-input > input[type="text"] {
  width: 80%;
  flex-grow: 1;
  outline: none;
  border: none;
  border-radius: 0.5rem;
  padding: 1rem 1rem;
}

.Alias-input > button {
  background-color: var(--color-primary-dark);
  border-radius: 0 0.5rem 0.5rem 0;
  width: 2.5rem;
  height: 2.5rem;
}

.icon-btn-submit {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Alias-tag-container {
  width: 100%;
  margin-top: 0.5rem;
}

.Alias-tag-container > p {
  font-size: var(--font-size-small);
  color: var(--color-text-dark);
}

.Alias-tag {
  display: inline-table;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  background-color: #fff;
  border-radius: 10rem;
  padding: 0.5rem;
  font-size: var(--font-size-small);
  color: var(--color-primary-dark);
  cursor: pointer;
}

.Alias-tag > p {
  display: inline;
}

/****************************************
STYLES FOR SOCIAL MEDIA PLATFORM ADDITION
****************************************/

.Platform-btn-container {
  margin-top: 1rem;
  width: 100%;
}

.Platform-btn {
  display: inline-block;
  width: 33%;
  text-align: center;
  margin-top: 2rem;
}

.Platform-btn > img {
  height: 4rem;
  width: 4rem;
  border-radius: 5rem;
  /*   box-shadow: 0 8px 12px rgba(0, 0, 0, 0.3); */
  cursor: pointer;
}

.Platforms {
  height: fit-content;
  width: var(--full-width);
  padding: 0 1rem;
  overflow-y: scroll;
}

.Social-account-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  font-size: 0.9rem;
  justify-content: center;
  align-items: center;
}

.Social-account-info > p {
  padding: 0.5rem 1px;
}

@media only screen and (max-width: 600px) {
  .Ob-page-influencer {
    justify-content: flex-start;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .Ob-carousel-indicators {
    position: relative;
    display: block;
  }

  .Ob-carousel-container {
    width: 100vw;
    overflow-x: scroll;
    margin-top: 12px;
    justify-content: flex-start;
  }

  .Ob-carousel-container::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }

  .Ob-carousel-container {
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
  }

  .Ob-card-container {
    scroll-snap-align: center;
    min-width: 94vw;
  }

  .Ob-carousel-indicator-container {
    text-align: center;
  }

  .Ob-carousel-indicator {
    width: 8px;
    height: 8px;
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 100%;
    display: inline-block;
    margin: 0 3px;
    transition: all 0.3s ease-in;
  }

  .Ob-carousel-indicator-active {
    width: 40px;
    background-color: #fff;
    border-radius: 20px;
  }

  .Ob-card-image {
    width: 90%;
  }

  .Platforms {
    height: fit-content;
  }

  .Profile-form {
    padding: 1rem 0.5rem;
    overflow-x: hidden;
    height: fit-content;
  }

  .Profile-form > form {
    width: 95%;
  }

  .Profile-form-page,
  .Profile-form-dashboard-container {
    /*   position: absolute;
    top: 0;
    left: 0; */
    width: 100%;
    /* height: var(--full-viewport-height); */
    display: block;
  }

  .Profile-form-dashboard-container {
    border-radius: 0;
    height: fit-content;
    margin: 0;
    padding: 0.4rem;
  }

  .Form-action-items {
    border-radius: 0 0 1rem 1rem;
    padding: 0 1rem;
  }

  .Grid-form-container {
    padding: 0.5rem;
  }

  .Signin-heading > p {
    width: 100%;
  }

  .Signup-input {
    justify-content: space-between;
    width: 17rem;
    margin: auto;
  }

  .Signup-input > input {
    width: 14rem;
  }

  .Divider-line {
    width: 8rem;
  }

  .Social-signin-container > button {
    width: 100%;
  }
}

/* @media only screen and (max-height: 550px) {
  .Giveaway-create-header {
    border-bottom: 4px solid var(--color-primary-light-background);
  }

  .Giveaway-create-buttons > button {
    opacity: 0%;
    visibility: hidden;
  }
} */
