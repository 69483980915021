body {
  max-height: 100vh;
  overflow: hidden;
}

.App {
  width: 100%;
  height: var(--full-viewport-height);
}

::-webkit-scrollbar {
  display: block !important;
  width: 4px;
  height: 4px;
  background-color: none;
}

::-webkit-scrollbar-thumb {
  background: #acacac;
  border-radius: 5rem;
}

.Loader {
  height: var(--full-viewport-height);
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Loader-inline {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Loader-img {
  height: 100px;
  width: 100px;
}

.Loader-img-dot {
  height: 30px;
  width: fit-content;
}

.Loader-animation {
  animation: OCLoader 0.75s ease-in-out infinite;
}

/* @keyframes OCLoader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: rgb(243, 243, 243);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.SignIn {
  position: absolute;
  top: 0rem;
  left: 0;
  background-color: white;
  height: 100%;
  width: 100%;
  padding-top: 12.5%;
}

.Google-sign-in-btn {
  font-size: 1.5rem;
  padding: 0.5rem 1rem;
}

.Landing {
  background-image: url(./assets/img/landing/color_mix.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  padding-top: 2.5%;
}

.Landing-content {
  width: 100%;
  height: 90%;
  margin: auto;
  text-align: center;
  padding: 2rem;
}

.Role-header {
  grid-area: header;
  color: #7000c1;
}

.Manager-role {
  grid-area: manager;
  height: 12rem;
  background-image: url(./assets/img/svg/sign_in_landing/manager.svg);
}

.Influencer-role {
  grid-area: influencer;
  background-image: url(./assets/img/svg/sign_in_landing/influencers.svg);
}

.Influencer-role,
.Manager-role {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #7000c1;
  outline: #f6f6f6 solid 4px;
  transition: outline 0.3s, background-color 0.3s, color 0.3s;
}

.Influencer-role:hover {
  color: #e8505b;
  outline: #e8505b solid 4px;
  background-color: #f9f9f9;
}

.Manager-role:hover {
  color: #0576e6;
  outline: #0576e6 solid 4px;
  background-color: #f9f9f9;
}

.Landing-content > h1 {
  margin: unset;
}

.Navbar {
  grid-area: nav;
  display: flex;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  background-color: #fff;
  border-bottom: solid 2px #e6e6e6;
  position: sticky;
}

.Nav-logo {
  height: 2rem;
  width: 200px;
  text-align: center;
}

.Navbar > a {
  color: #00b09b;
  font-size: 1.5rem;
  font-variant: small-caps;
  font-weight: 700;
  text-decoration: none;
}

.Error-fallback {
  width: 100%;
  height: 100vh;
  padding: 0 3rem;
}

.Side-nav-container-mobile {
  position: absolute;
  left: 0;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
}

.Side-nav-mobile {
  width: 12rem;
  height: 100%;
  background-color: #fff;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.Side-nav-mobile > p {
  margin: 1rem;
}
.Side-nav-mobile > ul {
  list-style: none;
  padding: unset;
}

.Sidebar-container {
  position: absolute;
  width: 12rem;
  max-width: 45vw;
  left: 12rem;
  top: 0;
  background-color: #fff;
  height: 100%;
  padding-top: 133px;
}

.Sidebar-container > a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 500;
  display: block;
  padding: 1rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.Menu-option {
  color: #fff;
  filter: invert(80%);
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
}

ul > a {
  text-decoration: none;
  color: #fff;
}

.Menu-icon > img {
  max-width: 1.5rem;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  12.5% {
    transform: rotate(45deg);
  }
  25% {
    transform: rotate(90deg);
  }
  37.5% {
    transform: rotate(135deg);
  }
  50% {
    transform: rotate(180deg);
  }
  62.5% {
    transform: rotate(225deg);
  }
  75% {
    transform: rotate(270deg);
  }
  87.5% {
    transform: rotate(315deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#animator {
  animation: rotation ease-in-out infinite 10s;
}
