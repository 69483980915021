@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --container-height-full: 100%;
  --container-width-full: 100%;
  --full-viewport-height: calc(var(--vh) * 100);
  --full-width: 100%;
  --width-input-large: 17rem;
  --width-input-giveaway-large: 20rem;
  --width-btn-large: 20rem;
  --width-btn-small: 6.5rem;

  --popup-box-width: 25rem;

  --display-container: block;
  --visibility-responsive: none;
  --visibility-desktop: visible;

  --color-primary: #d3f6fd;
  --color-primary-light: #84d2ce26;
  --color-primary-light-background: #edf8f8;
  --color-primary-dark: #1f1f1f;
  --color-header-light: #fff;
  --color-accent: #7d38ee;
  --color-accent-20: rgba(125, 56, 238, 0.2);
  --color-accent-10: rgba(125, 56, 238, 0.1);

  --color-accent-influencer: #7d38ee;
  --color-accent-manager: #7d38ee;

  --color-input-background: var(--color-primary-black-05);
  --color-input-background-filled: #f7f6ff;
  --color-input-outline-active: var(--color-accent);

  --font-color-input-placeholder: var(--color-primary-black-60);
  --font-color-input: var(--color-primary-black);
  --color-input-disabled: #e0e0e0;
  --color-input-disabled-text: #545454;

  --color-button-primary: #3b8d9e;
  --color-button-secondary: #fff;
  --color-button-tertiary: #f6f6f6;
  /* --color-button-hover: #ff0570; */
  --color-button-disabled: #a7a7a7;
  --color-button-delete: #e84237;

  --color-success: #33b065;
  --color-danger: #ff0000;

  --color-danger-box-title: #ffcaca;
  --color-danger-box-content: #ff9d9d;
  --color-danger-text: /* #bd0000 */ #ff0000;
  --color-suggestion-box-title: #caffec;
  --color-suggestion-box-content: #9dffdc;
  --color-suggestion-text: #22856d;

  --color-facebook-blue: #1877f2;
  --color-google-blue: #4285f4;

  --font-size-small: 0.8rem;
  --font-size-heading: 1.5rem;
  --font-size-normal: 1.2rem;
  --font-size-input-text: 14px;
  --font-size-input-area: 1rem;

  --font-color-primary: var(--color-primary-black);
  --font-color-primary50: var(--color-primary-black-60);
  --font-color-black: rgba(0, 0, 0, 0.8);
  --font-color-grey: #595959;

  --font-size-btn-sidemenu: 0.95rem;
  --font-size-header-medium: 1.1rem;
  --font-size-header-large: 1.2rem;

  --grid-dashboard-layout-areas: "sidenav nav" "sidenav body_content";
  --grid-dashboard-layout-columns: 70px calc(100vw - 70px);
  --grid-dashboard-layout-rows: 55px calc(var(--full-viewport-height) - 55px);

  --grid-dashboard-areas: "sidebar_header page_content"
    "sidebar_menu page_content";
  --grid-dashboard-columns: minmax(235px, min-content) auto;
  --grid-dashboard-rows: 135px calc(100% - 135px);

  --grid-empty-dashboard-areas: "heading" "role_selection" "role_content"
    "action_button" "superpowers";
  --grid-empty-dashboard-columns: 100%;
  --grid-empty-dashboard-rows: 135px 2fr 2fr 2fr 4fr;

  --grid-registration-layout-areas: "form infographic";
  --grid-registration-layout-columns: 43% 1fr;

  --grid-giveaway-create-areas: "header" "form";
  --grid-giveaway-create-rows: minmax(90px, auto) 1fr;
  --grid-giveaway-create-columns: 100%;
  --grid-giveaway-create-container-background: #fff;
  --giveaway-create-container-width: 600px;
  --grid-giveaway-create-background: #fff;

  --color-primary-black: #1f1f1f;
  --color-primary-black-80: rgba(31, 31, 31, 0.8);
  --color-primary-black-60: rgba(31, 31, 31, 0.6);
  --color-primary-black-40: rgba(31, 31, 31, 0.4);
  --color-primary-black-20: rgba(31, 31, 31, 0.2);
  --color-primary-black-10: rgba(31, 31, 31, 0.1);
  --color-primary-black-05: rgba(31, 31, 31, 0.05);
  --color-primary-black-03: rgba(31, 31, 31, 0.03);

  /*  --gradient-primary: linear-gradient(to right, #6300d3, #eb3eb2); */
  --gradient-primary: linear-gradient(10deg, #8729d1, #7252f0);
  --gradient-bg: linear-gradient(10deg, #8729d1, #7252f0);

  --color-scrollbar: var(--color-primary-black-05);
}

html,
body,
div,
form,
button,
input,
textarea,
.App {
  font-family: "Poppins", sans-serif;
  scrollbar-width: none;
  border: unset;
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

h2,
h3,
h4,
h5,
p,
form,
ul {
  margin: unset;
  padding: unset;
}

div,
input,
textarea,
button,
form,
input:focus {
  box-sizing: border-box;
  border: unset;
  outline: unset;
}

img {
  width: inherit;
  height: inherit;
  object-fit: contain;
  border-radius: inherit;
}

ul {
  list-style: none;
}

div::-webkit-scrollbar-thumb {
  background-color: var(--color-scrollbar);
}

.Container {
  width: 100%;
  height: 100%;
}

.text-bold {
  font-weight: 600 !important;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-underline {
  text-decoration: underline;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600 !important;
}

.fc-black {
  color: #1f1f1f;
}

.fc-accent {
  color: var(--color-accent);
}

.fc-primary-dark {
  color: var(--color-primary-dark);
}

.fc-pb-60 {
  color: var(--color-primary-black-60);
}

.font-size-small {
  font-size: 0.8rem !important;
}

.font-size-medium {
  font-size: 0.9rem;
}

.font-size-regular {
  font-size: 1rem;
}

.font-size-large {
  font-size: 1.2rem;
}

.fs-0-9 {
  font-size: 0.9rem;
}

.fs-1 {
  font-size: 1rem;
}
.font-color-primary {
  color: var(--font-color-primary);
}

.font-color-black {
  color: rgba(0, 0, 0, 0.8);
}

.font-color-light-black {
  color: rgba(0, 0, 0, 0.5);
}

.font-color-white {
  color: #fff;
}

.height-100 {
  height: 100%;
}

.width-100 {
  width: var(--container-height-full);
}

.text-align-center {
  text-align: center !important;
}

.box-align-center-top {
  margin: auto 0;
}

.box-align-center {
  margin: auto !important;
}

.box-align-center-horizontal {
  margin-left: auto !important;
  margin-right: auto !important;
}

.padding-bottom-12 {
  padding-bottom: 12px;
}

.padding-bottom-16 {
  padding-bottom: 16px;
}

.border-bottom-grey-e6 {
  border-bottom: 2px solid #e6e6e6;
}

.border-bottom-grey {
  border-bottom: 2px solid rgba(0, 0, 0, 0.08);
}

.border-bottom-lgreen {
  border-bottom: 2px solid #ecf4f6;
}

.border-bottom-dgreen-1 {
  border-bottom: 1px solid var(--color-primary-dark);
}

.border-dashed-primary-black {
  border: dashed 1.5px var(--color-primary-black);
}

.border-1-solid-pb10 {
  border: solid 1px var(--color-primary-black-10);
}

.border-bottom-1-solid-pb10 {
  border-bottom: solid 1px var(--color-primary-black-10);
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-column > div {
  width: 100%;
}

.flex-row {
  display: flex;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-space-between,
.flex-jsb {
  justify-content: space-between;
}

.flex-justify-space-evenly {
  justify-content: space-evenly;
}

.flex-align-center {
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.close-button-round {
  background-color: #ffe6e6;
  color: #ff4545;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  text-align: center;
  padding: 0.25rem 0;
  cursor: pointer;
}

.mb-12 {
  margin-bottom: 12px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-24 {
  margin-top: 24px;
}

.margin-top-small {
  margin-top: 0.5rem;
}

.margin-top-1 {
  margin-top: 1rem;
}

.margin-bottom-small {
  margin-bottom: 0.5rem;
}

.margin-bottom-1 {
  margin-bottom: 1rem;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-left-small {
  margin-left: 0.5rem;
}

.margin-left-medium {
  margin-left: 0.8rem;
}

.mv-1 {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.margin-auto-h {
  margin-left: auto;
  margin-right: auto;
}

.margin-auto-left {
  margin-left: auto;
}

.margin-auto-right {
  margin-right: auto;
}

.no-styles {
  background: none !important;
  border: none !important;
  max-height: min-content !important;
  border-radius: 0;
  box-shadow: none;
  width: 100%;
  margin: 1rem 0;
  padding: 0 0;
}

.height-small {
  min-height: 15rem !important;
  max-height: 15rem !important;
}

.text-primary {
  font-size: var(--font-size-normal);
  font-weight: 400;
}

.text-secondary {
  font-size: 0.9rem;
  color: gray;
}

.span-text-bold {
  font-weight: 600;
}

.span-text {
  color: var(--color-accent);
}

.span-align-left {
  float: left;
}

.span-align-right {
  float: right;
}

.span-text-note {
  color: var(--color-danger);
}

.bkg-circle-grey {
  background-color: #c4c4c4;
  border-radius: 12rem;
  padding: 0.2rem 0.4rem;
}

.bkg-grey-f4 {
  background-color: #f4f4f4 !important;
}

.bkg-primary-light {
  background-color: var(--color-primary-light-background) !important;
}

.bkg-primary {
  background-color: var(--color-primary-dark) !important;
}

.accent-text {
  color: var(--color-accent);
  /* font-weight: inherit; */
}

.accent-text-main {
  color: var(--color-accent-influencer);
  font-weight: 600;
  text-align: center;
}

.text-align-left {
  text-align: left !important;
}

.text-align-right {
  text-align: right !important;
}

.padding-1 {
  padding: 1rem;
}

button {
  border: none;
  font-weight: 500;
  border-radius: 0.6rem;
  cursor: pointer;
  background: none;
  font-family: "Poppins", sans-serif;
  /*   padding: 0.4rem 0.8rem; */
  padding: 8px 16px;
  border-radius: 2rem;
  transition: border-radius 0.2s;
}

.btn.text-link:hover {
  background-color: rgba(0, 0, 0, 0) !important;
}

button:hover {
  /*  background: var(--gradient-primary);
  color: #fff !important; */
  border-radius: 0.8rem;
  /*  background-color: var(--color-primary-black-10); */
  /*outline: #048071 solid 3px;
  background-color: #0576e6;
  box-shadow: 2px 2px 8px #4d4d4d75;*/
}

/* button:hover > span > img,
button:hover > img {
 
  filter: hue-rotate(0) saturate(0) brightness(100) invert(1);
} */

button > img {
  object-fit: contain;
}

.btn-danger {
  background-color: var(--color-button-delete);
  color: #fff;
}

.btn-grey {
  border-radius: 5rem;
  background-color: #dedede;
  color: #000 !important;
}

.btn-disabled {
  background-color: var(--color-button-disabled) !important;
}

.btn {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  min-width: 5rem;
  font-weight: 400;
}

.width-10 {
  width: 10%;
}

.width-45 {
  width: 45%;
}

.background-overlay-mint {
  background-color: #3b8e9e57;
}

.br-all-0-5 {
  border-radius: 0.5rem;
}

.br-rsides-0-5 {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.border-radius-inherit-bottom {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.Text-link {
  font-size: 14px;
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
}

/* .Btn {

  height: 44px;
  padding: 8px 24px;
  font-weight: 500;
  border-radius: 60px;
  font-size: 14px;
  font-weight: 600 !important;
} */

.Btn-half {
  width: 50%;
}

.Btn-primary {
  background-color: #000;
  color: #fff;
}

.Btn-secondary {
  background-color: #fff;
  color: #000;
}

.Btn-full {
  width: 100%;
  max-width: 330px;
  margin-left: auto;
  margin-right: auto;
}

/* .Btn:hover {
  background: linear-gradient(to right, #6300d3, #b626bf, #eb3eb2);
  color: #fff;
  background-color: #fff;
} */

.btn-primary-icon-round {
  border-radius: 100% !important;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-primary-icon-round > span {
  height: 24px;
  width: 24px;
}

.btn-gradient {
  background: var(--gradient-primary);
  color: #fff;
}

.btn-accent {
  background-color: var(--color-accent-influencer);
  color: #fff;
}

.btn-with-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.Btn-with-icon-right {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding-right: 20px !important;
}

.Btn-with-icon-right > img {
  height: 20px;
  width: 20px;
}

.btn-with-icon > span {
  height: 24px;
  width: 24px;
}

.icon-24 {
  height: 24px;
  width: 24px;
}

.span-icon-round {
  height: 24px;
  width: 24px;
}

.span-icon-small {
  height: 26px;
  width: 26px;
}

.span-icon-mini {
  height: 15px;
  width: 15px;
}

.dp-100 {
  height: 100px;
  width: 100px;
  object-fit: cover;
  object-position: center;
}

.text-light-grey {
  color: rgba(0, 0, 0, 0.5);
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.scrollx {
  overflow-x: scroll;
}

.height-fit {
  height: fit-content !important;
}

.btn-primary,
.btn-primary-icon-round {
  color: rgba(255, 255, 255, 1);
  background-color: var(--color-primary-black);
}

.btn-secondary {
  background-color: var(--color-button-secondary);
  color: var(--color-primary-dark) !important;
}

/* .btn-secondary:hover {
  background-color: var(--color-primary-light-background);
} */

.btn-tertiary {
  background-color: rgba(0, 0, 0, 0.03);
  color: var(--color-primary-dark) !important;
}

/* .btn-tertiary:hover {
  background-color: var(--color-primary-light-background);
} */

.btn-large {
  text-align: center;
  min-width: 12rem;
  /*  height: 2.3rem; */
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.btn-small {
  /*   height: 2.3rem; */
  font-size: 1rem;
  min-width: 6rem;
  font-weight: 400;
}

.icon-btn-small {
  width: 1rem;
  height: 1rem;
}

.icon-btn {
  width: 1.7rem;
  height: 1.7rem;
  border-radius: 100%;
}

.box-square-4 {
  height: 4rem !important;
  min-width: 4rem !important;
}

.box-square-5 {
  height: 5rem !important;
  width: 5rem !important;
}

.square-126 {
  width: 106px !important;
  height: 106px !important;
}
/*.svg-hover-fill:hover {
  stroke: rgba(255, 255, 255, 0.8);
}*/
a {
  text-decoration: none;
}

.Sidebar-menu-light > a > .text-btn-icon {
  font-size: var(--font-size-btn-sidemenu);
  font-weight: 600;
  color: var(--font-color-black);
  width: 12rem;
  text-align: left;
  height: 3rem;
  margin: 0.5rem 1.2rem;
  padding: 2rem;
  display: flex;
  gap: 10px;
  align-items: center;
}

.Side-menu-btn-active > button {
  background-color: var(--color-primary-dark);
  color: #fff !important;
}

.text-btn-icon > img {
  width: 1.5rem;
  height: 1.5rem;
}

.Side-menu-btn-active > button > img {
  filter: brightness(0) invert(1);
}

.Side-menu-btn-active > button:hover {
  background-color: var(--color-primary-dark) !important;
}

.Side-menu-btn-active > button > .btn-tag,
.Side-menu-btn-active > button > .btn-tag-yellow,
.Side-menu-btn-active > button > .btn-tag-red,
.Side-menu-btn-active > button > .btn-tag-green {
  background-color: #fff;
  color: #333333;
}

.btn-tag {
  width: 1rem;
  height: 1rem;
  padding: 0.3rem;
  border-radius: 5rem;
  font-size: var(--font-size-small);
  float: right;
  text-align: center;
}

.tag-red {
  background-color: #ed4141;
  color: #fff;
}
.tag-yellow {
  background-color: #f6a406;
  color: #fff;
}

.tag-green {
  background-color: #66b660;
  color: #fff;
}

.tag-grey {
  background-color: #ecf4f6;
}

.text-btn-icon:hover {
  background-color: #f4f4f4;
}

.icon-btn > img {
  width: 100%;
  transition: all 0.3s;
  border-radius: 100%;
}

/* .icon-btn > img:hover {
  filter: invert(200%);
  filter: brightness(285%);
} */

.btn-primary-form {
  font-size: 14px;
  /* height: 2.5rem; */
  width: 65%;
  min-width: 40px;
  height: 44px;
  /*min-width: var(--width-btn-small);*/
  /*  margin: auto 0; */
  font-weight: 600;
}

.btn-secondary-form {
  min-width: fit-content;
  width: 30%;
  height: 44px;
  font-size: 14px;
  font-weight: 600;
}

.btn-primary-form {
  background-color: var(--color-primary-black);
  color: #fff;
}

.btn-secondary-form {
  background-color: var(--color-primary-black-10);
  color: var(--color-primary-black);
}

.btn-green {
  background-color: var(--color-primary-dark);
  color: #fff;
}

.Avatar-img {
  width: 4rem;
  height: 4rem;
  text-align: center;
  color: var(--color-primary-dark) !;
  font-size: var(--font-size-small);
  margin: 0.5rem auto 0.5rem auto;
}

.text-link {
  color: var(--color-accent) !important;
  text-decoration: underline;
  font-size: var(--font-size-small);
  display: inline;
  cursor: pointer;
  font-weight: 600;
}

.tab-selected {
  background-color: var(--color-accent) !important;
  color: #fff !important;
  outline: none !important;
}

.inline-span {
  display: inline;
}

.section-heading {
  font-size: var(--font-size-small);
  text-align: left;
  color: var(--color-text-dark);
  width: 100%;
}

.visibility-responsive {
  display: var(--visibility-responsive) !important;
}

.visibility-desktop {
  display: var(--visibility-desktop) !important;
}

.Active-step {
  display: inline;
  position: relative;
  z-index: 1;
}

.blur-shadow {
  position: absolute;
  background-color: rgba(3, 121, 129, 0.8);
  width: 12px;
  height: 12px;
  border-radius: 15rem;
  top: 7px;
  right: 4px;
  bottom: 0;
  filter: blur(6px);
  z-index: -1;
}

input[type="text"]:disabled {
  background-color: rgba(0, 0, 0, 0.07);
  color: var(--color-input-disabled-text);
}

.cursor-pointer {
  cursor: pointer;
}
.button-tag {
  width: 14rem;
  height: 3rem;
  background-color: #fff;
  color: var(--color-accent);
  border-radius: 2rem;
  text-align: center;
  cursor: pointer;
  margin: 0.5rem 0;
  font-weight: 500;
}

.button-tag > p {
  padding: 0.75rem 0;
}

.Form-field-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
}

.Form-field-flex > label {
  font-size: 0.9rem;
  text-align: left;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
}

.Form-field-flex > input[inputmode="numeric"] {
  max-width: 6rem;
  text-align: center;
}

.Form-image-input {
  display: grid;
  grid-template-areas:
    "preview selection"
    "presets presets";
  grid-template-rows: 8rem auto;
  grid-template-columns: 8rem auto;
  row-gap: 15px;
}

.Form-image-preview {
  grid-area: preview;
  border-radius: 1rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: solid 1px var(--color-primary-black-05);
}

.Form-image-selection {
  grid-area: selection;
  text-align: center;
}

.Form-image-presets {
  grid-area: presets;
  display: flex;
  width: 100%;
  padding: 0.75rem 0;
  overflow-y: visible;
  overflow-x: scroll;
}

.Image-preset-option {
  margin-right: 0.5rem;
  cursor: pointer;
}

.Upload-button {
  font-size: 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  color: var(--color-primary-dark);
  padding: 0.5rem 1rem;
  display: flex;
  margin: auto;
}

.Upload-button:hover {
  background-color: #fff;
}

.Upload-button > span {
  height: 1.5rem;
}

.Inf-avatar > img {
  width: 100%;
  height: 100%;
}

.dotted-divider {
  width: 60%;
  height: 2px;
  border-top: 1px dashed #95b5be;
  margin: 16px auto;
}
span > img {
  height: inherit;
  width: inherit;
}

.btn-full {
  width: 100%;
  padding: 0.6rem 0rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #c6d4d4;
  box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.1);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.1);
}

input:checked + .slider {
  background-color: var(--color-primary-dark);
}

input:focus + .slider {
  box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.1);
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

input[type="checkbox"] {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 0.2rem;
  accent-color: var(--font-color-primary);
}

textarea::-webkit-scrollbar-thumb {
  background: linear-gradient(
    rgba(0, 0, 0, 0) 10%,
    var(--color-scrollbar) 10%,
    var(--color-scrollbar) 90%,
    rgba(0, 0, 0, 0) 90%
  );
}

.User-avatar-nav {
  font-weight: 500;
  border-radius: 100%;
  height: 50px !important;
  width: 50px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  /*   border-width: 2px;
  border-style: solid;
  border-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 15%,
      #fff 15%,
      #fff 85%,
      rgba(0, 0, 0, 0) 85%
    )
    0% 0% 100% 0%; */
  margin-bottom: calc(22px + 0.5rem);
}

.User-avatar-nav {
  background-color: #595959;
  outline: solid 4px #fff;
  color: #fff;
  font-size: 1.3rem;
  font-weight: 300;
  object-fit: cover;
}

.Alias-avatar-img {
  height: 50px;
  width: 50px;
  border-radius: 25rem;
  object-fit: cover;
}

.Alias-avatar-img > img {
  outline: solid 4px #fff;
  object-fit: cover;
}

.Card-avatar {
  background-color: #bbb8b8;
  color: #fff;
  font-size: 1.3rem;
  font-weight: 300;
  font-weight: 500;
  border-radius: 100%;
  height: 100% !important;
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.solid-divider-thick {
  width: 80%;
  height: 2px;
  background-color: var(--color-primary-dark);
  margin: auto;
}

.sticky-top-scroll {
  position: sticky;
  top: 0;
}

.SH3 {
  font-size: 14px;
}

@media only screen and (max-width: 800px) {
  .visibility-desktop {
    display: none !important;
  }
  :root {
    --grid-dashboard-layout-areas: "sidenav nav" "sidenav body_content";
    --grid-dashboard-layout-columns: 0 100vw;
    --grid-dashboard-layout-rows: 55px calc(var(--full-viewport-height) - 55px);

    --grid-dashboard-areas: "sidebar_header page_content"
      "sidebar_menu page_content";
    --grid-dashboard-columns: 0 100%;
    /*  --grid-dashboard-rows: 100%; */

    --grid-empty-dashboard-areas: "heading" "role_selection" "role_content"
      "action_button" "superpowers";
    --grid-empty-dashboard-columns: 100%;
    --grid-empty-dashboard-rows: 7rem 15rem auto 8rem auto;

    --grid-registration-layout-areas: "form infographic";
    --grid-registration-layout-columns: 100% 0;

    /* --grid-giveaway-create-container-background: linear-gradient(
      0deg,
      #ff3a4a,
      #ff8c00,
      #40e0d0
    ); */
    /* --grid-giveaway-create-background: #edf8f8; */
    --giveaway-create-container-width: 100%;
    /*  --grid-giveaway-create-rows: 20% auto 10%; */

    --display-container: flex;
    --visibility-responsive: visible;
    --visibility-desktop: none;

    --popup-box-width: 90%;
    --width-input-large: 75%;
    --width-input-giveaway-large: 90%;
    --width-btn-large: 65%;
    --full-width: 100vw;
  }

  .Text-link {
    font-size: 12px;
  }
  ::-webkit-scrollbar {
    display: block !important;
    width: 4px !important;
    height: 4px !important;
    background-color: rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar-thumb {
    background: var(--color-scrollbar);
    border-radius: 5rem;
    margin-right: 0.3rem;
  }

  /* .btn-primary:hover {
    background: var(--color-button-primary) !important;
  } */

  .dotted-divider {
    width: 80%;
  }
  .SH3 {
    font-size: 12px;
  }
}
