@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  /*  --full-viewport-height: 100vh; */
  /****************************
##############################
******** Colors ************
##############################
*****************************/
  --color-pb: #1f1f1f;
  --color-pb-03: rgba(31, 31, 31, 3%);
  --color-pb-05: rgba(31, 31, 31, 5%);
  --color-pb-10: rgba(31, 31, 31, 10%);
  --color-pb-20: rgba(31, 31, 31, 20%);
  --color-pb-40: rgba(31, 31, 31, 40%);
  --color-pb-60: rgba(31, 31, 31, 60%);
  --color-pb-80: rgba(31, 31, 31, 80%);
  --color-pb-03-solid: #f8f8f8;
  --color-pb-05-solid: #f4f4f4;
  --color-pb-10-solid: #e9e9e9;
  --color-pb-20-solid: #d2d2d2;
  --color-pb-40-solid: #a5a5a5;
  --color-pb-60-solid: #797979;
  --color-pb-80-solid: #4c4c4c;

  --color-dull-white: #fbfafd;
  --color-dull-white-80: rgba(251, 250, 253, 80%);
  --color-dull-white-50: rgba(251, 250, 253, 50%);

  --color-accent-solid: #7d38ee;
  --color-accent-05-solid: #f9f5fe;
  --color-accent-10-solid: #eee7fc;
  --color-accent-20-solid: #e5d7fc;

  --color-red-solid: #e84237;
  --color-red-10-solid: #fdeceb;
  --color-red-20-solid: #fad9d7;

  --color-accent-gradient: linear-gradient(30deg, #8729d1, #7252f0);
  --color-pi-gradient: linear-gradient(
    75deg,
    #6300d3 10%,
    #b626bf 55%,
    #eb3eb2 100%
  );

  /****************************
##############################
******** Buttons ************
##############################
*****************************/
  --color-button-primary: var(--color-pb);
  --color-button-secondary: var(--color-pb-05);
  --color-button-tertiary: none;

  --width-button-medium: 44px;
  --height-button-medium: 44px;

  --padding-left-button-text-medium: 24px;
  --padding-right-button-text-medium: 24px;
  --padding-right-button-text-icon-right-medium: 20px;
  --padding-left-button-text-icon-left-medium: 20px;

  /****************************
##############################
******** FONT SIZES ********
##############################
*****************************/
  --font-size-H1: 26px;
  --font-size-H2: 22px;
  --font-size-H3: 20px;
  --font-size-H4: 18px;

  --font-size-SH1: 18px;
  --font-size-SH2: 16px;
  --font-size-SH3: 14px;

  --font-size-BT1: 16px;
  --font-size-BT2: 14px;
  --font-size-BT3: 12px;

  --icon-size-small: 20px;
  --icon-size-medium: 22px;
  --icon-size-large: 26px;
}

/****************************
##############################
********* MEDIA QUERY ********
##############################
*****************************/

@media only screen and (max-width: 800px) {
  :root {
    /****************************
    ##############################
    /********* FONT SIZES ********
    ##############################
    *****************************/
    --font-size-H1: 24px;
    --font-size-H2: 20px;
    --font-size-H3: 18px;
    --font-size-H4: 16px;

    --font-size-SH1: 16px;
    --font-size-SH2: 14px;
    --font-size-SH3: 12px;

    --font-size-BT1: 14px;
    --font-size-BT2: 12px;
    --font-size-BT3: 10px;

    --icon-size-small: 18px;
    --icon-size-medium: 20px;
    --icon-size-large: 24px;

    /****************************
    ##############################
    ******** Buttons ************
    ##############################
    *****************************/
    --padding-left-button-text-medium: 20px;
    --padding-right-button-text-medium: 20px;
    --padding-right-button-text-icon-right-medium: 16px;
    --padding-left-button-text-icon-left-medium: 16px;
  }
}

@media only screen and (min-width: 801px) and (max-width: 1200px) {
}

/****************************
##############################
******** TEXT ****************
##############################
*****************************/
.H1 {
  font-size: var(--font-size-H1);
}

.H2 {
  font-size: var(--font-size-H2);
}

.H3 {
  font-size: var(--font-size-H3);
}

.H4 {
  font-size: var(--font-size-H4);
}

.SH1 {
  font-size: var(--font-size-SH1);
}

.SH2 {
  font-size: var(--font-size-SH2);
}

.SH3 {
  font-size: var(--font-size-SH3);
}

.BT1 {
  font-size: var(--font-size-BT1);
}

.BT2 {
  font-size: var(--font-size-BT2);
}

.BT3 {
  font-size: var(--font-size-BT3);
}

/****************************
  ##############################
  ******** BUTTONS *************
  ##############################
  *****************************/

.Btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  gap: 8px;
  /*   margin: 12px 0; */
  transition: border-radius linear 0.2s;
  cursor: pointer;
}

.Btn-primary {
  background-color: var(--color-button-primary);
  color: #fff;
}

.Btn-secondary {
  background-color: var(--color-button-secondary);
  color: var(--color-pb);
}

.Btn-tertiary {
  background-color: var(--color-button-tertiary);
  color: var(--color-pb);
}

.Btn-accent {
  background: var(--color-accent-gradient);
  color: #fff;
}

.Btn-icon {
  border-radius: 50px;
}

.Btn-icon-medium {
  width: var(--width-button-medium);
  height: var(--height-button-medium);
}

.Btn-text {
  border-radius: 32px;
}

.Btn-text-medium {
  height: var(--height-button-medium);
  padding-left: var(--padding-left-button-text-medium);
  padding-right: var(--padding-right-button-text-medium);
  font-size: var(--font-size-BT2);
}

.Btn-text-icon-left-medium {
  height: var(--height-button-medium);
  flex-direction: row-reverse;
  padding-left: var(--padding-left-button-text-icon-left-medium);
  padding-right: var(--padding-right-button-text-medium);
  font-size: var(--font-size-BT2);
}

.Btn-text-icon-right-medium {
  height: var(--height-button-medium);
  padding-right: var(--padding-right-button-text-icon-right-medium);
  padding-left: var(--padding-left-button-text-medium);
  font-size: var(--font-size-BT2);
}

.Btn:hover {
  border-radius: 12px;
}

.Btn-icon:hover {
  border-radius: 55px !important;
}

.Text-label {
  display: block;
  font-weight: 600;
  font-size: var(--font-size-SH2);
  padding-left: 2px;
  margin-bottom: 8px;
  text-transform: capitalize;
}

.Input-text {
  width: 100%;
  background-color: var(--color-pb-05);
  border-radius: 12px;
  padding: 12px;
  font-size: var(--font-size-SH2);
  font-weight: 500;
  height: 44px;
}

.Input-text:focus {
  background-color: var(--color-accent-05-solid);
  border: solid 2px var(--color-accent-solid);
}

.Input-text::placeholder {
  color: var(--color-pb-60);
  font-size: var(--font-size-SH3);
  font-weight: 500;
}

.Input-text-error,
.Input-text-error:focus {
  background-color: var(--color-red-10-solid);
  border: solid 2px var(--color-red-solid);
}

.Bottom-sheet-container {
  width: 100vw;
  height: var(--full-viewport-height);
  position: fixed;
  z-index: 13;
  display: grid;
  top: 0;
  left: 0;
}

.Bottom-sheet-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.Bottom-sheet {
  width: 375px;
  max-height: 600px;

  position: fixed;
  z-index: 11;
  align-self: center;
  justify-self: center;
  background: #fff;

  overflow-y: scroll;
  padding-bottom: 32px;
  border-radius: 14px;
}

.Bottom-sheet::-webkit-scrollbar,
.Bottom-sheet::-webkit-scrollbar-thumb {
  width: 0 !important;
  height: 0 !important;
}

.Bottom-sheet-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: var(--font-size-H2);
  font-weight: 500;
  color: var(--color-pb);
}

.Bottom-sheet > * {
  padding-left: 24px;
  padding-right: 24px;
}

@media only screen and (max-width: 600px) {
  .Bottom-sheet {
    width: calc(100vw - 32px);
    max-height: 85vh;
    left: 16px;
    bottom: 16px;
  }
}
