.Claim-sheet-container {
  background-image: url("../img/claim_popup_image.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: calc(100% + 48px);
  aspect-ratio: 1/1;
  margin: -64px -24px 0 -24px;

  border-radius: 12px;
  position: relative;
  top: 0;
  z-index: 3;
}

.Bottom-sheet-header {
  position: relative;
  z-index: 4;
}

.Completed-sheet-container {
  margin: -58px -24px -32px -24px;
  position: relative;
  z-index: 3;
}

.Completed-sheet-container > .graphic {
  width: 100%;
  height: clamp(300px, 50%, 372px);
  object-fit: cover;
  object-position: bottom;
  margin-bottom: calc(30px - 5%);
  transform: translateY(-5%);
  animation: motion-1 ease-in-out 0.2s 0.05s, motion-2 ease-in-out 0.2s 0.25s,
    motion-3 ease-in-out 0.2s 0.45s, motion-4 ease-in-out 0.2s 0.65s,
    motion-5 ease-in-out 0.2s 0.85s;
}

@keyframes motion-1 {
  from {
    transform: translateY(-105%);
  }
  to {
    transform: translateY(0%);
  }
}

@keyframes motion-2 {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(-10%);
  }
}

@keyframes motion-3 {
  from {
    transform: translateY(-10%);
  }
  to {
    transform: translateY(-2%);
  }
}

@keyframes motion-4 {
  from {
    transform: translateY(-2%);
  }
  to {
    transform: translateY(-8%);
  }
}

@keyframes motion-5 {
  from {
    transform: translateY(-8%);
  }
  to {
    transform: translateY(-5%);
  }
}

@keyframes opacity {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}

.Completed-sheet-container > div {
  text-align: center;
  color: #fff;
  animation: opacity 0.7s linear 0.1s;
}

.Bottom-sheet-content-negative {
  margin: -64px auto auto auto;
}

.Campaign-congratulations-sheet-container {
  margin: 4px -20px 0 -20px;
  padding: 20px 20px 20px 20px;
  border-radius: 10px;
  background: var(--color-accent-gradient);
  text-align: center;
}

.Background-ellipse {
  width: 100%;
  height: 100%;
  background-image: url("../img/svg/background_ellipse.svg");
  background-size: 100%;
  background-position: center 65%;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
  margin: 0 -20px;
  z-index: 0;
}

.Campaign-congratulations-sheet-container > .Amazon-card {
  width: 60%;
  z-index: 1;
  position: relative;
}

.Campaign-congratulations-sheet-container > .congratulations-snowflake {
  margin: -10px -20px;
  width: 100%;
}

.Campaign-congratulations-subtext {
  margin-top: -20px;
}

.Campaign-congratulations-subtext > .happy-underline {
  position: relative;
  top: -10px;
  left: 30px;
}

@media screen and (max-width: 600px) {
  .Campaign-congratulations-sheet-container > .Amazon-card {
    width: 80%;
  }

  .Campaign-congratulations-sheet-container {
    margin: 6px -20px 0 -20px;
  }
}

.Campaign-reward-customization-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: var(--color-pb-05);
  border-radius: 12px;
  margin: 0 0 12px 0;
  padding: 12px;
}

.Campaign-reward-customization-item > .Coupon-denomination-graphic {
  height: 50px;
  width: 50px;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
